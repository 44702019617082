import { accountsActions } from './accounts-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';

export const fetchAccountsList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(accountsActions.setIsLoading(true))
            const url = store.getState().auth.url
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const response = await fetch( url + 'api/accounts', {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch accounts list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const accountsData = await fetchData()
            dispatch(accountsActions.loadAccounts({ accounts: accountsData || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(accountsActions.setIsLoading(false))
        }
    };
};

export const setAccountIsLoading = (value) => {
    return (dispatch) => {
        dispatch(accountsActions.setIsLoading(value))
    }
}

