import { settingsActions } from './settings-slice'
import store from './index'
import { authActions } from './auth-slice';

export const fetchNeededSettings = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/websettings',{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch settings.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const settingsData = await fetchData()
            dispatch(settingsActions.setNeededSettings(settingsData))

        } catch (error) {
           // dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

