import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: { 
     notification: null,
     errorMessage: null,
     sidePopup: null
    },
  reducers: {
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
    showErrorMessage(state, action){
      
      state.errorMessage = {message: action.payload}
    },
    showErrorMessageWithLink(state, action){
      state.errorMessage = {message: action.payload.message, link: action.payload.link}
    },
    showSidePopup(state, action){
      //expecting to get {status: 'success', header: '', message: ''}
      state.sidePopup = action.payload
    },
    resetErrorMessage(state){
      state.errorMessage = null
    },
    resetNotification(state) {
      state.notification = null
    }
  }
});

export const uiActions = uiSlice.actions;
export default uiSlice;
