import { shulsActions } from './shuls-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';

export const fetchShulsList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(shulsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/shuls',{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch shuls list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const shulsData = await fetchData()
            dispatch(shulsActions.loadShuls({ shuls: shulsData || [] }))
            dispatch(shulsActions.setIsLoading(false))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const setShulssIsLoading = (value) => {
    return (dispatch) => {
        dispatch(shulsActions.setIsLoading(value))
    }
}

