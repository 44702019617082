import { tendersActions } from './tenders-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';

export const fetchTendersList = (type, donorID, selectedTenderID) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(tendersActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(`${url}api/${type}/${donorID}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch tenders list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const tendersData = await fetchData()
            dispatch(tendersActions.loadAllTenders({ tenders: tendersData || [], selectedTenderID: selectedTenderID }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(tendersActions.setIsLoading(false))

        }
    };
};

export const fetchAllTendersLists = (donorID,) => {
    return async (dispatch) => {
        const fetchData = async (type) => {
            dispatch(tendersActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(`${url}api/${type}/${donorID}`,{
                headers:{
                    'Authorization': bearer,
                }
            });
            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch tenders list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const CCData = await fetchData('donorstenderscreditcards')
            dispatch(tendersActions.loadTendersType({ tenders: CCData || [], type: 'CC'}))

            const ACHData = await fetchData('donorstenderschecksach')
            dispatch(tendersActions.loadTendersType({ tenders: ACHData || [], type: 'ACH'}))

            const OJCData = await fetchData('ojc')
            dispatch(tendersActions.loadTendersType({ tenders: OJCData || [], type: 'OJC'}))

            const TheDonorsFundData = await fetchData('thedonorsfund')
            dispatch(tendersActions.loadTendersType({ tenders: TheDonorsFundData || [], type: 'TheDonorsFund'}))

            const PledgerData = await fetchData('pledger')
            dispatch(tendersActions.loadTendersType({ tenders: PledgerData || [], type: 'Pledger'}))

            const MatbiaData = await fetchData('matbia')
            dispatch(tendersActions.loadTendersType({ tenders: MatbiaData || [], type: 'Matbia'}))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(tendersActions.setIsLoading(false))
        }
    };
};




export const setSelectdTender = (tenderID) => {
    return (dispatch) => {
        dispatch(tendersActions.setSelectedTender(tenderID))
    }
}
