import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboards: [],
    adminDashboards: [],
    dashboardsLoading: false,
    loadedAllDashboards: 0,
    loadedSettings: false,
    parents: [],
    parentsLoading: false,
    listForNewTask: null, //parents, donors, students, etc.
    listForNewTaskLoading: false,
    reminderTypes: [],
    reminderTypesLoading: false,
    reminderNames: [],

}
const hubSlice = createSlice({
    name: "hub",
    initialState,
    reducers: {
        loadTuitionTasks(state, action) {
            state.tuitionTasks = action.payload.tasks;
        },
        setTuitionTasksLoading(state, action){
            state.tuitionTasksLoading = action.payload
        },
        loadDashboards(state, action){
            // if(state.dashboards.length > 0){ //all tasks got loaded first
            //     let da = action.payload.dashboards
            //     state.dashboards.forEach(d => {
            //         const dashboard = da.find(da => da.DashboardSettingID === d.DashboardSettingID)
            //         if(dashboard){
            //             dashboard.Tasks = d.Tasks
            //             dashboard.Totals = d.Totals
            //             dashboard.CompletedTasks = d.CompletedTasks
            //         }
            //     });
            //     state.dashboards = da
            //     state.loadedAllDashboards += 1
            // }
            //else{
                state.dashboards = action.payload.dashboards.filter(d => !d.AdminDashboard)
                state.adminDashboards = action.payload.dashboards.filter(d => d.AdminDashboard)
            //}
            state.loadedSettings = true
        },
        loadAllTasks(state, action){
            //replace tasks in dashboards and totals
            //if(state.dashboards.length > 0){
                action.payload.dashboards.forEach(d => {
                    let dashboard = state.dashboards.find(da => da.DashboardSettingID === d.DashboardSettingID)
                    if(!dashboard){
                        dashboard = state.adminDashboards.find(da => da.DashboardSettingID === d.DashboardSettingID) 
                    }
                    if(dashboard){
                        dashboard.Tasks = d.Tasks
                        dashboard.Totals = d.Totals
                        dashboard.CompletedTasks = d.CompletedTasks
                        dashboard.NumberOfTasksForCompleted = d.NumberOfTasksForCompleted
                        dashboard.SmsTemplates = d.SmsTemplates
                    }
                });
                state.loadedAllDashboards += 1
            // }
            // else{ //all tasks got loaded first
            //     state.dashboards = action.payload.dashboards
            // }            
        },
        replaceTask(state, action){
            //payload should be {dashboardId: 0, task: {}}
            let dashboard = state.dashboards.find(d => d.DashboardSettingID === action.payload.dashboardId)
            if(dashboard){
                let task = dashboard.Tasks.find(t => t.DonorID === action.payload.task.DonorID)
                if(task){
                    task = action.payload.task
                }
            }
        },
        removeTaskFromList(state, action){
            //payload should be {dashboardId: 0, ID: 0}
            let dashboard = state.dashboards.find(d => d.DashboardSettingID === action.payload.dashboardId)
            if(dashboard){
                dashboard.Tasks = dashboard.Tasks.filter(t => t.ID !== action.payload.ID)
            }
        },
        setDashboardsLoading(state, action){
            state.dashboardsLoading = action.payload
        },
        loadParents(state, action) {
            state.parents = action.payload.parents;
        },
        setParentsLoading(state, action){
            state.parentsLoading = action.payload
        },
        loadListForNewTask(state, action) {
            state.listForNewTask = action.payload.list;
        },
        setListForNewTaskLoading(state, action){
            state.listForNewTaskLoading = action.payload
        },
        loadReminderTypes(state, action) {
            state.reminderTypes = action.payload.types.map(t => {
                return {name: t, value: t}
            });
        },
        loadReminderNames(state, action){
            state.reminderNames = action.payload.names.map(t => {
                return {name: t, value: t}
            });
        },
        setReminderTypesLoading(state, action){
            state.reminderTypesLoading = action.payload
        },
        loadSMSTemplates(state, action){
            state.smsTemplates = action.payload.templates
        },

        resetState:() => initialState,
    },
});

export const hubActions = hubSlice.actions;

export default hubSlice;
