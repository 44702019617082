import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultAmounts: null,
    cashPayment: true,
    checkPayment: true,
    settingsLoaded: false
}

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setNeededSettings(state, action) {
            let s = action.payload.find(a => a.Name === 'Default Amounts')
            if(s){
                state.defaultAmounts = s.Value;
            }
            s = action.payload.find(a => a.Name === 'Payment by Cash')
            if(s){
                state.cashPayment = s.Value === 'true' ? true : false
            }
            s = action.payload.find(a => a.Name === 'Payment by Check')
            if(s){
                state.checkPayment = s.Value === 'true' ? true : false
            }
            state.settingsLoaded = true
        },
        resetState:() => initialState,
    },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice;
