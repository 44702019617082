import { useEffect, useState } from "react"
import classes from './TaskQueries.module.css'
import formatFieldByFieldName from "../../../../functions/formatFieldByFieldName"

const TaskQueries = (props) => {
    const [queries, setQueries] = useState([])
    const [selectedQuery, setSelectedQuery] = useState()

    useEffect(()=>{
        if(props.queries.length > 0){
            let q = [...props.queries]
            q.forEach(e => {
                try{
                    e.Table = JSON.parse(e.Table)
                }
                catch{ }
            }); 
            setQueries(q)
            if(q.length > 0){
                if(sessionStorage.getItem('selectedTaskQuery') && q.find(i => i.Caption === sessionStorage.getItem('selectedTaskQuery'))){
                    setSelectedQuery(q[q.findIndex(i => i.Caption === sessionStorage.getItem('selectedTaskQuery'))])
                }
                else{
                    setSelectedQuery(q[0])
                }
            }
        }
    },[props.queries])

    const changeSelectedQuery = (query) => {
        setSelectedQuery(query)
        sessionStorage.setItem('selectedTaskQuery', query.Caption)
    }
        
    return (
        <div className={classes.queries}>
            <div className={classes.captions}>
            {selectedQuery && queries.map(q => {
               return <p key={q.Caption} className={`${classes.caption} ${selectedQuery.Caption === q.Caption && classes.active}`} onClick={() => changeSelectedQuery(q)}>{q.Caption}</p>
            })}
            </div>
            {selectedQuery && selectedQuery.Table.length > 0 && <div className={classes.headers}>
            {Object.entries(selectedQuery.Table[0]).map(([_key, value]) => {
                return <p className={classes.header} key={_key}>{_key}</p>
            })}
                </div>}
                <div className={classes.rows}>
            {selectedQuery && selectedQuery.Table.map((r, i) => {
                return <div className={classes.row} key={i}>{Object.entries(r).map(([_key, value]) => {
                    return <div key={_key} className={classes.row}>
                        <p className={classes['row-value']}>{formatFieldByFieldName(_key, value ? value.toString() : '')}</p>
                    </div>
                })}
                </div>
            })}
            </div>
        </div>
    )
}

export default TaskQueries