import classes from './TaskList.module.css'
import Arrow from '../../../../images/Arrow - Up 2.svg'
import { useState } from 'react'
import AddButton from '../../../UI/Buttons/AddButton'
import { useNavigate } from 'react-router-dom'

const TaskList = (props) => {
    const [showList, setShowList] = useState(props.defaultOpened)
    const navigate = useNavigate()

    const addTaskHandler = () => {
        navigate(`new-task?dashboard=${props.name}`)
    }

    return <div className={classes.list}>
        
        <div className={classes.header}>
            <div className={classes.line}/>
            <p className={classes.view} onClick={() => setShowList(prevState => !prevState)}>View Tasks ({props.total})<img alt='' src={Arrow}/></p>
            <div className={classes.line}/>
        </div>
        {showList && props.children}
        {!props.adminDashboard && <div className={classes.footer}>
            <AddButton green onClick={addTaskHandler}>New Task</AddButton>
        </div>}
    </div>
}

export default TaskList