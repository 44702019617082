import { statementsActions } from './statements-slice';
import store from './index' 
import { authActions } from './auth-slice';


export const fetchStatementsList = (id = 0) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(statementsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/statements/' + id, {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch statements list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const statementData = await fetchData()
                dispatch(statementsActions.loadStatement({ statement: statementData || [] }))
        } catch (error) {

        }
        finally {
            dispatch(statementsActions.setIsLoading(false))

        }
    };
};

