import classes from './TaskListItem.module.css'
import completed from '../../../../images/check - task completed.svg'
import notCompleted from '../../../../images/check - task not completed.svg'
import TaskActions from '../TaskActions/TaskActions'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { donorsActions } from '../../../../store/donors-slice'
import { fetchDonorsAddressesList, fetchDonorsContactsList, fetchDonorsDetails } from '../../../../store/donors-actions'
import { fetchAllTendersLists } from '../../../../store/tenders-actions'
const TaskListItem = (props) => {
    const [balance, setBalance] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch
    ()

    useEffect(() => {
        var amount = parseFloat(props.task.Balance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        setBalance(amount)
    }, [props.task.Balance])

    const showDetailsHandler = () => {
        navigate(`task-details?setting=${props.dashboardSettingId}&id=${props.task.ID}`)
    }

    const clickIdHandler = (e) => {
        e.stopPropagation()
        if(window.location.href.indexOf("-app") === -1 && props.settings.WorkWith && props.settings.WorkWith === 'Donors'){
                dispatch(donorsActions.setSelectedDonor(props.task.ID));
                dispatch(fetchDonorsDetails(props.task.ID));
                dispatch(fetchDonorsAddressesList(props.task.ID));
                dispatch(fetchDonorsContactsList(props.task.ID));
                dispatch(fetchAllTendersLists(props.task.ID));
                navigate("/donors-list/donor-details?hub=1");
        }
    }
    return <div className={classes.task}>
        <div className={classes.info}>
            <img alt='' src={props.completed ? completed : notCompleted}/>
        <p className={classes.title} onClick={showDetailsHandler}>{props.settings.RowFormatText || 'Call'} {props.settings.RowFormatLastNameFirst && props.settings.RowFormatLastNameFirst.toLowerCase() === 'true' ? `${props.task.LastName} ${props.task.FirstName} ` : `${props.task.FirstName} ${props.task.LastName} `}
         <span id={`taskId ${props.dashboardSettingId} ${props.task.ID}`} className={classes.parentid} onClick={clickIdHandler}>
            {props.task.ID}</span></p>
            <div className={classes.details}>
                {props.task.FieldsToDisplay.length > 0 && props.task.FieldsToDisplay.map((f, i) =>{
                    return <p className={classes.detail} key={i}>{f.Value}</p>
                } )}
            </div>
        </div>
        {!props.adminDashboard && <TaskActions cancelco={props.cancelco} task={props.task} dashboardSettingId={props.dashboardSettingId} actions={props.settings.ActionsOnDropdown} onOpenDetails={showDetailsHandler} workWith={props.settings.WorkWith}/>}
    </div>
}

export default TaskListItem
