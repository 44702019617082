
import classes from './AddTask.module.css'
import ModelNew from '../../../UI/Modals/ModalNew'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CustomSelect from '../../../UI/Dropdowns/CustomSelect'
import TextField from '../../../UI/Inputs/TextField'
import DateInput from '../../../UI/Inputs/DateInput'
import ButtonMOut from '../../../UI/Buttons/Buttons-Outline/ButtonMOut'
import ButtonM from '../../../UI/Buttons/Buttons/ButtonM'
import { useEffect, useState } from 'react'
import CurrentDateForDateInput from '../../../../functions/Dates/datesForDateInput'
import CustomSelectFreeText from '../../../UI/Dropdowns/CustomSelectFreeText'
import DonorSearchBox from '../../../UI/SearchBoxs/DonorSearchBox/DonorSearchBox'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboards, fetchListForNewTask, fetchReminderTypes, fetchReminderNames } from '../../../../store/hub-actions'
import PinkTextButton from '../../../UI/Buttons/TextButtons/PinkTextButton'
import { converDateForBackendWithCurrentTime } from '../../../../functions/Dates/ConvertDateForC#'
import useHttp from '../../../../hooks/use-http'
import { uiActions } from '../../../../store/ui-slice'
import Input from '../../../UI/Inputs/Input'
import { getAddMessageBody } from '../../../../functions/getMessageBody'

const AddTask = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {sendRequest: saveTask, isLoading} = useHttp()
    const [searchParams] = useSearchParams()

    const types = useSelector((state) => state.hub.reminderTypes)
    const names = useSelector((state) => state.hub.reminderNames)
    const list = useSelector((state) => state.hub.listForNewTask)
    const dashboards = useSelector((state) => state.hub.dashboards)

    const [listObj, setListObj] = useState([])
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [selectedObj, setSelectedObj] = useState(null)
    const [selecteId, setSelectedId] = useState()
    const [selectedIdInvalid, setSelectedIdInvalid] = useState()
    const [description, setDescription] = useState('')
    const [dueDate, setDueDate] = useState(CurrentDateForDateInput())

    useEffect(()=>{
        if(types.length === 0){
            dispatch(fetchReminderTypes())
        }
        if(names.length === 0 && (!searchParams.get('dashboard') || searchParams.get('id'))){ 
            dispatch(fetchReminderNames())
        }
    },[])

    useEffect(()=>{
        dispatch(fetchListForNewTask(searchParams.get('dashboard') ? parseInt(searchParams.get('dashboard')) : 0))
        setSelectedObj(null)
    },[])

    useEffect(()=>{
        let id
        if(searchParams.get('dashboard') && dashboards.length > 0){
            id = parseInt(searchParams.get('dashboard'))
            if(id && !searchParams.get('id')){
                let d = dashboards.find(da => da.DashboardSettingID === id)
                if(d){
                    setName(d.Name)
                }
            }
           
        }
    },[dashboards, searchParams])

    useEffect(() => {
        if(list){
            let lst = []
            if(list.Donors){
                lst = list.Donors.map(donor => {
                    return {value: donor.DonorsID, type: 'Donor', name: donor.FirstName + " " + donor.LastName, phoneHome: donor.PhoneHome, phoneMobile: donor.PhoneMobile, email: donor.Email, address: {Address: donor.Address, City:  donor.City, State: donor.State, Zip: donor.Zip}}
                })
            } 
            else if(list.Parents){
                lst = list.Parents.map(parent => {
                    return {value: parent.ParentID, type: 'Parent', name: parent.FirstName + " " + parent.LastName, phoneHome: parent.PhoneHome, phoneMobile: parent.PhoneMobile, email: parent.Email, address: {Address: parent.Address, City:  parent.City, State: parent.State, Zip: parent.Zip}}
                })
            }
            else if(list.Students){
                lst = list.Students.map(student => {
                    return {value: student.StudentID, type: 'Student', name: student.FirstName + " " + student.LastName, phoneHome: student.PhoneHome, phoneMobile: student.PhoneMobile, email: student.Email, address: {Address: student.Address, City:  student.City, State: student.State, Zip: student.Zip}}
                })
            }
            setListObj(lst)
        }
    },[list])

    useEffect(() => {
        if(listObj.length > 0 && searchParams.get('id')){
            changeObjHandler(parseInt(searchParams.get('id')))
        }
    },[listObj, searchParams])


    const closeNewTaskHandler = () => {
        navigate('..')
    }

    const changeTypeHandler = (value) => {
        setType(value)
    }

    const changeNameHandler = (value) => {
        setName(value)
    }

    const changeObjHandler = (id) => {
        setSelectedId(id)
        setSelectedIdInvalid(false)
        const obj = listObj.find(p => p.value === id)
        if(obj){
            setSelectedObj(obj)
        }
        else{
            setSelectedObj(null)
        }
    }

    const saveTaskHandler = () => {
        if(!selecteId){
            setSelectedIdInvalid(true)
            return
        }
        const task = {
            ID: selecteId,
            DashboardID: searchParams.get('dashboard') ? parseInt(searchParams.get('dashboard')) : 0,
            Type: type || '',
            Description: description,
            Name: name || '',
            Date: converDateForBackendWithCurrentTime(dueDate)
        }
        saveTask({url: 'api/saveTask', method: 'POST', body: task, headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },}, handleTaskResponse)
    }

    const handleTaskResponse = (data) => {
        if(data.ActionSuccessful){
            dispatch(uiActions.showSidePopup({status: 'success', header: getAddMessageBody('task')}))
            dispatch(fetchDashboards(true))
            navigate(-1)
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: getAddMessageBody('task', false), message: data.ActionResultMessage}))
        }
    }

    return (
        <ModelNew title='Task' onClose={closeNewTaskHandler} className={classes.white} modelClassName={classes.model} backdropClassName={classes.backdrop}>
            <CustomSelectFreeText selectedValue={type} options={types} label='Type' onChange={changeTypeHandler} />
            <div className={classes.dropdowns}>
                {(!searchParams.get('dashboard') || searchParams.get('id')) && <CustomSelectFreeText options={names} label='Name' onChange={changeNameHandler} className={classes.name} selectedValue={name}/>}
                {searchParams.get('dashboard') && !searchParams.get('id') && <Input label='Name' readOnly value={name}  className={classes.name} />}
                <DateInput label='Due Date' value={dueDate} onChange={(v) => setDueDate(v)} className={classes.date}/>
            </div>
            <div>
            {selectedIdInvalid && listObj.length > 0 && <p className={classes.invalid}>Select {listObj[0].type}</p>}
            {!selectedObj && listObj.length > 0 && <DonorSearchBox list={listObj} placeholder={`Search ${listObj[0].type}`} onSelect={changeObjHandler} className={classes['donor-search']}/>}
            {selectedObj && <div className={classes['parent-wrapper']}>
                <p className={classes['parent-label']}>{selectedObj.type}</p>
                <div className={classes['connected-parent']}>
                <p className={classes.parentid}>{selectedObj.value}</p>
                <p className={classes['parent-name']}>{selectedObj.name}</p>
                <PinkTextButton className={classes['remove-parent']} onClick={() => changeObjHandler(null)}>Remove</PinkTextButton>
                </div></div>}
            </div>
            <TextField label='Description' value={description} onChange={(v) => setDescription(v)}/>
            <div className={classes.buttons}>
                <ButtonMOut onClick={closeNewTaskHandler}>Cancel</ButtonMOut>
                <ButtonM onClick={saveTaskHandler} loading={isLoading}>Save</ButtonM>
            </div>
        </ModelNew>
    )
}

export default AddTask