import { studentsActions } from './students-slice';
import store from './index' 
import { authActions } from './auth-slice';


export const fetchStudentsList = (id = 0) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(studentsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/students/' + id, {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch students list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const studentsData = await fetchData()
            dispatch(studentsActions.loadStudents({ students: studentsData || [] }))
        } catch (error) {

        }
        finally {
            dispatch(studentsActions.setIsLoading(false))

        }
    };
};

