import { organizationsActions } from './organizations-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';

export const fetchOrganizationsList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(organizationsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/organizations',{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch organizations list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const organizationsData = await fetchData()
            dispatch(organizationsActions.loadOrganizations({ organizations: organizationsData || [] }))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(organizationsActions.setIsLoading(false))

        }
    };
};

