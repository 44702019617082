import { solicitorsActions } from './solicitors-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';

export const fetchSolicitorsList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(solicitorsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/solicitors',{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch solicitors list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const solicitorsData = await fetchData()
            dispatch(solicitorsActions.loadSolicitors({ solicitors: solicitorsData || [] }))
            dispatch(solicitorsActions.setIsLoading(false))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const setSolicitorsIsLoading = (value) => {
    return (dispatch) => {
        dispatch(solicitorsActions.setIsLoading(value))
    }
}

