import { campaignsActions } from './campaigns-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';

export const fetchCampaignsList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(campaignsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/campaigns',{
                headers:{
                    'Authorization': bearer,
                }

            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch campaigns list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const campaignsData = await fetchData()
            dispatch(campaignsActions.loadCampaigns({ campaigns: campaignsData || [] }))
        } catch (error) {
            dispatch(uiActions.showNotification(error.message))
        }
        finally {
            dispatch(campaignsActions.setIsLoading(false))

        }
    };
};
export const fetchSubCampaignsList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(campaignsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/subcampaigns', {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch sub campaigns list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const campaignsData = await fetchData()
            dispatch(campaignsActions.loadSubCampaigns({ subCampaigns: campaignsData || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(campaignsActions.setIsLoading(false))

        }
    };
};

export const setCampaignIsLoading = (value) => {
    return (dispatch) => {
        dispatch(campaignsActions.setIsLoading(value))
    }
}

